import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('isLoading', {
  state: () => ({
    homeSectionMapFilters: false,
    productSuggestionsFilters: false,
    gridResults: false,
    pagination: false
  }),
  getters: {
    getIsLoading: state => {
      return (loadingContext: keyof typeof state): boolean => state[loadingContext];
    }
  },
  actions: {
    setIsLoading(loadingContext: keyof typeof this.$state, value: boolean): void {
      this[loadingContext] = value;
    }
  }
});
