/**
 * This store is used to store data that is fetched once and used in multiple places around the app
 * For example, categories, seasons, etc...
 * Make sure to use $fetch API and not strapi module that is not wodking in useAsyncData
 * To fetch the data, we call this store actions in the app.vue file that is the root file of the project
 *
 * Make sure to create custom actions for each data entity as the models are not the same
 *
 * For each action, we need to parse the query to an encoded string
 * This query has the filters and populate options
 *
 * The url is built using the useCompleteUrl composables that will accept a devbackendpoint enum that represents the strapi entity
 * Then we pass the parsedQuery as the request query params
 */

import { defineStore } from 'pinia';
import { stringify } from 'qs';

import { limitPagination } from '@/lib/strapiFilters';

import { Category } from '@/lib/strapi-types/Category';
import { Journey } from '@/lib/strapi-types/Journey';

import { BackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { FilterDefinition } from '@/lib/types/filters';
import { StrapiQuery } from '@/lib/types/models/common';
import { JourneyModel } from '@/lib/types/models/journey';
import parseJourney from '@/lib/types/parsers/parseJourney';
import { Strapi4ResponseMany } from '@/lib/types/strapi';

export const useGlobalFetchedDataStore = defineStore('globalFetchedData', {
  state: () => ({
    periods: [] as Category[] | undefined,
    products: [] as JourneyModel[]
  }),
  getters: {
    // Periods
    getPeriodsAsFilters: state => {
      return state.periods
        ? (state.periods.map(i => ({
            id: i.id.toString(),
            name: i.attributes.title,
            identifier: 'seasons',
            strategy: 'urlKey',
            value: i.attributes.urlKey
          })) as FilterDefinition<'seasons'>[])
        : [];
    },

    // Products
    getProductsAsSuggestions: state => state.products
  },

  actions: {
    async fetchPeriods(): Promise<void> {
      const query = {
        filters: {
          parent: { urlKey: 'ou-partir', level: 2 }
        },
        pagination: limitPagination(0, 100)
      };
      const parsedQuery = stringify(query, {
        encodeValuesOnly: true
      });

      // Using $fetch and not strapi module because strapi module does not work in asyncData in pages
      this.periods = (
        await $fetch<Strapi4ResponseMany<Category['attributes']>>(
          `${useCompleteUrl(BackEndpoints.CATEGORIES).value}?${parsedQuery}`
        )
      ).data;
    },

    async fetchProducts(query: StrapiQuery, loadingContext: string): Promise<void> {
      const loadingStore = useLoadingStore();
      try {
        loadingStore.setIsLoading(loadingContext as keyof typeof loadingStore.$state, true);
        const parsedQuery = stringify(query, {
          encodeValuesOnly: true
        });

        // Using $fetch and not strapi module because strapi module does not work in asyncData in pages
        this.products = (
          await $fetch<Strapi4ResponseMany<Journey['attributes']>>(
            `${useCompleteUrl(BackEndpoints.JOURNEYS).value}?${parsedQuery}`
          )
        ).data.map((journey: any) => parseJourney(journey));
      } catch (error) {
        loadingStore.setIsLoading(loadingContext as keyof typeof loadingStore.$state, false);
      } finally {
        loadingStore.setIsLoading(loadingContext as keyof typeof loadingStore.$state, false);
      }
    }
  }
});
